/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.required::after {
  content: ' *';
  color: red; /* or any other color you prefer */
}

.k-form-error {
  font-weight: bold;
}

.k-expander-header {
  border-bottom: solid 1px gray;
}

.k-menu-group .k-link {
  padding: 10px !important;
}

.k-window-content {
  padding: 0 !important;
  margin: 0 !important;
}


/* Global */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--kendo-color-base-active);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div.jsoneditor-field,
div.jsoneditor-value {
  font-size: 9pt;
}

.ace_editor.ace-jsoneditor {
  min-height: 500px;
}

.k-overlay {
  background-color: lightgray !important;
  opacity: 0.4 !important;
}